<template>
  <v-container>
    <div class="overlay-alert">
      <v-alert
          dismissible
          type="success"
          v-model="alertSuccess"
          transition="scale-transition">
        success!
      </v-alert>
      <v-alert
          dismissible
          type="error"
          v-model="alertError"
          transition="scale-transition">
        Error!
      </v-alert>
    </div>

    <v-row>
      <v-col
          cols="12"
          sm="4"
          md="2"
      >
        <v-autocomplete v-model="year"
                        :items="years"
                        label="год"
                        dense
        >
        </v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
          cols="12"
          sm="4"
          md="2"
      >
        <v-btn
            color="primary"
            dark
            @click="save"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3>Январь</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesJanuary"
                       multiple
                       no-title
                       :first-day-of-week="1"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>февраль</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesFebruary"
                       multiple
                       no-title
                       :first-day-of-week="1"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Март</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesMarch"
                       multiple
                       no-title
                       :first-day-of-week="1"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Апрель</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesApril"
                       multiple
                       no-title
                       :first-day-of-week="1"
        ></v-date-picker>
      </v-col>
    </v-row>

    <v-row>

      <v-col>
        <h3>Май</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesMay"
                       multiple
                       no-title
                       :first-day-of-week="1"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Июнь</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesJune"
                       multiple
                       no-title
                       :first-day-of-week="1"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Июль</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesJuly"
                       multiple
                       no-title
                       :first-day-of-week="1"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Август</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesAugust"
                       multiple
                       no-title
                       :first-day-of-week="1"
        ></v-date-picker>
      </v-col>
    </v-row>

    <v-row>

      <v-col>
        <h3>Сентябрь</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesSeptember"
                       multiple
                       no-title
                       :first-day-of-week="1"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Октябрь</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesOctober"
                       multiple
                       no-title
                       :first-day-of-week="1"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Ноябрь</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesNovember"
                       multiple
                       no-title
                       :first-day-of-week="1"
        ></v-date-picker>
      </v-col>
      <v-col>
        <h3>Декабрь</h3>
        <v-date-picker class="show-days-only"
                       v-model="datesDecember"
                       multiple
                       no-title
                       :first-day-of-week="1"
        ></v-date-picker>
      </v-col>
    </v-row>

    <v-row>

    </v-row>

  </v-container>

</template>

<script>

import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'

export default {
  name: "ProductionCalendarEdit",

  data() {
    return {
      type: null,
      elapse: null,
      alertSuccess: false,
      alertError: false,
      years: [
        2020,
        2021,
        2022,
        2023,
        2024,
        2025,
      ],
      year: 0,
      datesJanuary: [],
      datesFebruary: [],
      datesMarch: [],
      datesApril: [],
      datesMay: [],
      datesJune: [],
      datesJuly: [],
      datesAugust: [],
      datesSeptember: [],
      datesOctober: [],
      datesNovember: [],
      datesDecember: [],
    }
  },
  watch: {
    year: function () {
      this.getAllDay()
    }
  },
  methods: {
    showAlertSuccess() {
      this.alertSuccess = true
      setTimeout(this.hideAlertSuccess, 1500);
    },
    hideAlertSuccess() {
      this.alertSuccess = false
    },
    showAlertError() {
      this.alertError = true
      setTimeout(this.hideAlertError, 1500);
    },
    hideAlertError() {
      this.alertError = false
    },
    save() {
      HTTP.post(
          Settings.API.URL_DEFAULT + "/production-calendar/date-list", {
            "year": this.year,
            "date": this.datesJanuary.concat(
                this.datesJanuary,
                this.datesFebruary,
                this.datesMarch,
                this.datesApril,
                this.datesMay,
                this.datesJune,
                this.datesJuly,
                this.datesAugust,
                this.datesSeptember,
                this.datesOctober,
                this.datesNovember,
                this.datesDecember,
            )
          })
          .then((response) => {
            console.log(response)
            this.getAllDay()
            this.showAlertSuccess()
          })
          .catch(() => {
            this.showAlertError()
          })
          .finally(() => {
            //this.loading = false
          });
    },
    getAllDay() {
      this.getJanuaryDys()
      this.getFebruaryDys()
      this.getMarchDys()
      this.getAprilDys()
      this.getMayDys()
      this.getJuneDys()
      this.getJulyDys()
      this.getAugustDys()
      this.getSeptemberDys()
      this.getOctober()
      this.getNovemberDys()
      this.getDecemberDys()

    },
    getJanuaryDys() {
      this.datesJanuary = []
      HTTP.get(
          Settings.API.URL_DEFAULT + "/production-calendar/by-year-and-month?month=1&year=" + this.year)
          .then((response) => {
            console.log(response)
            this.datesJanuary = response.data.map(function (item) {
              let date = new Date(item.date)
              let month = date.getMonth() + 1;
              return date.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (date.getDate() < 10 ? '0' : '') + date.getDate();
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });

    },
    getFebruaryDys() {
      this.datesFebruary = []
      HTTP.get(
          Settings.API.URL_DEFAULT + "/production-calendar/by-year-and-month?month=2&year=" + this.year)
          .then((response) => {
            console.log(response)
            this.datesFebruary = response.data.map(function (item) {
              let date = new Date(item.date)
              let month = date.getMonth() + 1;
              return date.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (date.getDate() < 10 ? '0' : '') + date.getDate();
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });

    },
    getMarchDys() {
      this.datesMarch = []
      HTTP.get(
          Settings.API.URL_DEFAULT + "/production-calendar/by-year-and-month?month=3&year=" + this.year)
          .then((response) => {
            console.log(response)
            this.datesMarch = response.data.map(function (item) {
              let date = new Date(item.date)
              let month = date.getMonth() + 1;
              return date.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (date.getDate() < 10 ? '0' : '') + date.getDate();
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });

    },
    getAprilDys() {
      this.datesApril = []
      HTTP.get(
          Settings.API.URL_DEFAULT + "/production-calendar/by-year-and-month?month=4&year=" + this.year)
          .then((response) => {
            console.log(response)
            this.datesApril = response.data.map(function (item) {
              let date = new Date(item.date)
              let month = date.getMonth() + 1;
              return date.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (date.getDate() < 10 ? '0' : '') + date.getDate();
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });

    },
    getMayDys() {
      this.datesMay = []
      HTTP.get(
          Settings.API.URL_DEFAULT + "/production-calendar/by-year-and-month?month=5&year=" + this.year)
          .then((response) => {
            console.log(response)
            this.datesMay = response.data.map(function (item) {
              let date = new Date(item.date)
              let month = date.getMonth() + 1;
              return date.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (date.getDate() < 10 ? '0' : '') + date.getDate();
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });

    },
    getJuneDys() {
      this.datesJune = []
      HTTP.get(
          Settings.API.URL_DEFAULT + "/production-calendar/by-year-and-month?month=6&year=" + this.year)
          .then((response) => {
            console.log(response)
            this.datesJune = response.data.map(function (item) {
              let date = new Date(item.date)
              let month = date.getMonth() + 1;
              return date.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (date.getDate() < 10 ? '0' : '') + date.getDate();
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });

    },
    getJulyDys() {
      this.datesJuly = []
      HTTP.get(
          Settings.API.URL_DEFAULT + "/production-calendar/by-year-and-month?month=7&year=" + this.year)
          .then((response) => {
            console.log(response)
            this.datesJuly = response.data.map(function (item) {
              let date = new Date(item.date)
              let month = date.getMonth() + 1;
              return date.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (date.getDate() < 10 ? '0' : '') + date.getDate();
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });

    },
    getAugustDys() {
      this.datesAugust = []
      HTTP.get(
          Settings.API.URL_DEFAULT + "/production-calendar/by-year-and-month?month=8&year=" + this.year)
          .then((response) => {
            console.log(response)
            this.datesAugust = response.data.map(function (item) {
              let date = new Date(item.date)
              let month = date.getMonth() + 1;
              return date.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (date.getDate() < 10 ? '0' : '') + date.getDate();
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });

    },
    getSeptemberDys() {
      this.datesSeptember = []
      HTTP.get(
          Settings.API.URL_DEFAULT + "/production-calendar/by-year-and-month?month=9&year=" + this.year)
          .then((response) => {
            console.log(response)
            this.datesSeptember = response.data.map(function (item) {
              let date = new Date(item.date)
              let month = date.getMonth() + 1;
              return date.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (date.getDate() < 10 ? '0' : '') + date.getDate();
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });

    },
    getOctober() {
      this.datesOctober = []
      HTTP.get(
          Settings.API.URL_DEFAULT + "/production-calendar/by-year-and-month?month=10&year=" + this.year)
          .then((response) => {
            console.log(response)
            this.datesOctober = response.data.map(function (item) {
              let date = new Date(item.date)
              let month = date.getMonth() + 1;
              return date.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (date.getDate() < 10 ? '0' : '') + date.getDate();
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });

    },
    getNovemberDys() {
      this.datesNovember = []
      HTTP.get(
          Settings.API.URL_DEFAULT + "/production-calendar/by-year-and-month?month=11&year=" + this.year)
          .then((response) => {
            console.log(response)
            this.datesNovember = response.data.map(function (item) {
              let date = new Date(item.date)
              let month = date.getMonth() + 1;
              return date.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (date.getDate() < 10 ? '0' : '') + date.getDate();
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });

    },
    getDecemberDys() {
      this.datesDecember = []
      HTTP.get(
          Settings.API.URL_DEFAULT + "/production-calendar/by-year-and-month?month=12&year=" + this.year)
          .then((response) => {
            console.log(response)
            this.datesDecember = response.data.map(function (item) {
              let date = new Date(item.date)
              let month = date.getMonth() + 1;
              return date.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (date.getDate() < 10 ? '0' : '') + date.getDate();
            })
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            //this.loading = false
          });

    },
  },
  created() {

  },
  components: {}
}
</script>

<style lang="css">
.show-days-only .v-date-picker-header {
  display: none;
}

.overlay-alert {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}


</style>