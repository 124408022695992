<template>
  <div>
    <div v-if="loading" class="progress-barr">
      <div class="progress">
        <v-progress-circular
            :size="100"
            :width="10"
            color="blue"
            indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <v-app id="schedule">
      <v-container justify-center>
        <v-flex xs12>
          <v-row v-if="this.$store.getters.isAdmin || this.$store.getters.isSdkSpo" class="d-print-none">
            <v-col
                cols="6"
                sm="6"
                md="6"
            >
              <v-autocomplete v-model="selectedYear"
                              :items="year"
                              label="Год"
                              dense
                              prepend-icon="event"
              >
              </v-autocomplete>
            </v-col>

            <v-col
                cols="6"
                sm="6"
                md="6"
            >
              <v-autocomplete v-model="selectedHalfYear"
                              :items="halfYear"
                              label="Полугодие"
                              dense
                              prepend-icon="event"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="d-print-none">
            <v-col
                cols="6"
                sm="6"
                md="6"
            >
              <v-autocomplete v-model="selectedGroup"
                              :items="groups"
                              label="Группа"
                              dense
                              :loading="groupsLoading"
                              prepend-icon="groups"
              >
              </v-autocomplete>
            </v-col>

            <v-col
                cols="6"
                sm="6"
                md="6"
            >
              <v-autocomplete v-model="selectedDiscipline"
                              :items="disciplines"
                              label="Дисциплина"
                              dense
                              :loading="disciplinesLoading"
                              prepend-icon="school"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-tabs
              v-model="tab" class="d-print-none"
          >
            <v-tab>
              <v-icon left>
                mdi-account
              </v-icon>
              Успеваемость
            </v-tab>
<!--            <v-tab>-->
<!--              <v-icon left>-->
<!--                mdi-lock-->
<!--              </v-icon>-->
<!--              Успеваемость-->
<!--            </v-tab>-->
            <v-tab>
              <v-icon left>
                mdi-access-point
              </v-icon>
              Контрольные точки
            </v-tab>
            <v-tab v-show="isAdmin() || isTeacher()">
              <v-icon left>
                mdi-access-point
              </v-icon>
              Курсовой проект(Ведомость)
            </v-tab>
            <v-tab v-show="isAdmin() || isTeacher()">
              <v-icon left>
                mdi-access-point
              </v-icon>
               Практика(Ведомость)
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-access-point
              </v-icon>
              Научная деятельность
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-access-point
              </v-icon>
              Компенсация
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-access-point
              </v-icon>
              Ведомость
            </v-tab>
          </v-tabs>
          <v-tabs-items
              v-model="tab"
              :touchless="true"
          >
            <v-tab-item

            >
              <journal
                  :groupId="selectedGroup"
                  :disciplineId="selectedDiscipline"
                  :yearId="selectedYear"
                  :halfYearId="selectedHalfYear"
              ></journal>
            </v-tab-item>
<!--            <v-tab-item-->

<!--            >-->
<!--              <grade-book-->
<!--                  :groupId="selectedGroup"-->
<!--                  :disciplineId="selectedDiscipline"-->
<!--              ></grade-book>-->
<!--            </v-tab-item>-->
            <v-tab-item

            >
              <control-points
                  :groupId="selectedGroup"
                  :disciplineId="selectedDiscipline"
                  :yearId="selectedYear"
                  :halfYearId="selectedHalfYear"
              ></control-points>
            </v-tab-item>
            <v-tab-item
                v-show="isAdmin() || isTeacher()"
            >
              <course-project v-if="selectedYear >= 3"
                  :groupId="selectedGroup"
                  :disciplineId="selectedDiscipline"
                  :yearId="selectedYear"
                  :halfYearId="selectedHalfYear"
                  @loading="loading = true"
                  @stop-loading="loading = false"
              />
              <course-project-old
                  v-if="selectedYear < 3"
                  :groupId="selectedGroup"
                  :disciplineId="selectedDiscipline"
                  :yearId="selectedYear"
                  :halfYearId="selectedHalfYear"
              />
            </v-tab-item>
            <v-tab-item
                v-show="isAdmin() || isTeacher()"
            >
              <practice-ved v-if="selectedYear >= 3"
                  :groupId="selectedGroup"
                  :disciplineId="selectedDiscipline"
                  :yearId="selectedYear"
                  :halfYearId="selectedHalfYear"
                  @loading="loading = true"
                  @stop-loading="loading = false"
              />
              <practice-ved-old v-if="selectedYear < 3"
                                :groupId="selectedGroup"
                                :disciplineId="selectedDiscipline"
                                :yearId="selectedYear"
                                :halfYearId="selectedHalfYear"
              />
            </v-tab-item>
            <v-tab-item

            >
              <scientific-activity
                  :groupId="selectedGroup"
                  :disciplineId="selectedDiscipline"
                  :yearId="selectedYear"
                  :halfYearId="selectedHalfYear"
              ></scientific-activity>
            </v-tab-item>
            <v-tab-item

            >
              <compensation-point
                  :groupId="selectedGroup"
                  :disciplineId="selectedDiscipline"
                  :yearId="selectedYear"
                  :halfYearId="selectedHalfYear"
              ></compensation-point>
            </v-tab-item>
            <v-tab-item

            >
              <exam
                  :groupId="selectedGroup"
                  :disciplineId="selectedDiscipline"
                  :year="selectedYear"
                  :halfYear="selectedHalfYear"
              ></exam>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-container>
    </v-app>

  </div>
</template>

<script>

import Exam from "@/views/Exam";
import Journal from "@/views/journal";
import ControlPoints from "@/views/ControlPoints";
import CourseProject from "@/views/CourseProject";
import ScientificActivity from "@/views/ScientificActivity";
import CompensationPoint from "@/views/CompensationPoint";
import PracticeVed from "@/views/PracticeVed";
// import Gradebook from "@/views/Gradebook";
import Settings from "@/settings/settings_url";
import {HTTP} from '@/settings/axios'
import PracticeVedOld from "@/views/PracticeVedOld";
import CourseProjectOld from "@/views/CourseProjectOld";

export default {
  name: "JournalAll",
  data() {
    return {
      tab: 1,
      year: [
        {
          text: "2020-2021",
          value: 1
        },
        {
          text: "2021-2022",
          value: 2
        },
        {
          text: "2022-2023",
          value: 3
        },
        {
          text: "2023-2024",
          value: 4
        },
        {
          text: "2024-2025",
          value: 6
        },
      ],
      halfYear:[
        {
          text: "1 полугодие",
          value: 1
        },
        {
          text: "2 полугодие",
          value: 2
        },
      ],
      selectedYear: 0,
      selectedHalfYear: 0,
      groups: [],
      selectedGroup: 0,
      disciplines: [],
      selectedDiscipline: 0,
      disciplinesLoading: false,
      groupsLoading: false,

      loading: false,
    }
  },
  watch: {
    tab: function () {
      //console.log(this.tab)
    },
    selectedYear(){
      this.getGroups();
      this.getDiscipline()
    },
    selectedHalfYear(){
      this.getGroups();
      this.getDiscipline()
    },
    selectedGroup: function () {
      this.getDiscipline()
    }
  },
  methods: {
    getGroups: async function () {
      if (this.selectedYear != 0 && this.selectedHalfYear != 0){
        this.groupsLoading = true
        await HTTP.get(Settings.API.URL_GROUPS_BY_ROLE_USER_BRSO(this.selectedYear, this.selectedHalfYear))
            .then((response) => {
              this.groups = response.data.map(function (item) {
                return {
                  text: item.name,
                  value: item.id
                }
              })
              //console.log(this.groups)
            })
            .catch(() => {
              this.errored = true;
            })
            .finally(() => {
              // this.calcSum()
              this.groupsLoading = false
            });
      }

    },
    getDiscipline: async function () {
      this.disciplinesLoading = true
      await HTTP.get(Settings.API.URL_DISCIPLINES_BY_ROLE_USER_BRSO(this.selectedGroup, this.selectedYear, this.selectedHalfYear))
          .then((response) => {
            this.disciplines = response.data.map(function (item) {
              return {
                text: item.name,
                value: item.id
              }
            })
            //console.log(this.disciplines)
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            // this.calcSum()
            this.disciplinesLoading = false
          });
    },
    isAdmin(){
      return this.isRole("ROLE_ADMIN")
    },
    isTeacher() {
      return this.isRole("ROLE_TEACHER")
    },
    isRole(role) {
      if (this.$store.getters.selectedUser?.role != null && this.$store.getters.selectedUser?.role.length > 0){
        for (let i = 0; i < this.$store.getters.selectedUser?.role.length; i++){
          if (
              this.$store.getters.selectedUser?.role[i].name === role
          ){
            return true
          }
        }
      }else {
        return  false
      }
    },
  },
  created() {
    // this.getGroups()
    this.tab = Number(this.$route.query.tab)
    if (
        this.$route.query.group != 0 && this.$route.query.discipline != 0  && this.$route.query.year != 0 && this.$route.query.halfYear != 0
        && this.$route.query.group != undefined && this.$route.query.discipline != undefined && this.$route.query.year != undefined && this.$route.query.halfYear != undefined
    ) {

      this.selectedGroup = Number(this.$route.query.group)
      this.selectedDiscipline = Number(this.$route.query.discipline)
      this.selectedYear = Number(this.$route.query.year)
      this.selectedHalfYear = Number(this.$route.query.halfYear)
      // console.log(this.selectedGroup)
    }else{
      this.selectedYear = 3
      this.selectedHalfYear = 1
    }
  },
  components: {
    CourseProjectOld,
    PracticeVedOld,
    'journal': Journal,
    // 'grade-book': Gradebook,
    'control-points': ControlPoints,
    'course-project': CourseProject,
    'scientific-activity': ScientificActivity,
    'compensation-point': CompensationPoint,
    'exam': Exam,
    'practice-ved': PracticeVed,
  }
}
</script>

<style>
@media print {
  div {
    float: none !important;
    position: static !important;
    display: inline;
    box-sizing: content-box !important;
  }
  body {
    overflow: visible;
  }
  .center-on-print {
    display: flex;
    justify-content: center;
  }
}

</style>
